<template>
  <div class="p-selector" @click="platformSelectorClick">
    <!-- <div class="p-s-i">
      {{ choiceIndex == -1 ||!dataList[choiceIndex]? defaultTxt : dataList[choiceIndex].name }}
    </div> -->
    <div class="triangle" :class="{open:selectorOpen}"></div>
    <div class="p-selector-wrap" :class="{open:selectorOpen}" :style="{height:selectorOpen && menuHeight? menuHeight:''}">
      <div class="p-s-r-name" :class="{selected:i == choiceIndex}" @click="selectorClicked(i)"
        v-for="(sItem,i) of dataList">
        {{ sItem.wallet_name ? sItem.wallet_name : sItem.address}}
      </div>
    </div>
  </div>
</template>
<script>
export default{
  props:{
    defaultIndex:Number,
    dataList:Array,
    menuHeight:String,
    resetIndex:{
      type:Number, //Math.random()
      required:false,
    }
  },
  data(){
    return {
      choiceIndex:-1,
      selectorOpen:false,
      selfClick:false,
    }
  },
  mounted(){
    if(typeof this.defaultIndex != 'undefined'){
      this.choiceIndex = this.defaultIndex;
    }
    window.addEventListener('click',() =>{
      if(!this.selfClick){
        this.selectorOpen = false
      }
      this.selfClick = false
    });
  },
  methods:{
    platformSelectorClick(){
      this.selfClick = true;
      this.selectorOpen = !this.selectorOpen
    },
    selectorClicked(i){
      this.choiceIndex = i
      this.$emit('change',i);
    }
  },
  watch:{
    resetIndex(){
      this.choiceIndex = 0;
    }
  }
}
</script>
<style scoped lang="stylus">
.p-selector
  border-radius .04rem
  color #fff1df
  width 1.2rem
  height auto
  line-height .24rem
  text-align left
  font-size .12rem
  .p-selector-wrap
    width 96%
    height auto
    box-shadow 0 5px 5px 0 rgba(0,0,0,.3)
    border-radius 0 0 5px 5px
    text-align center
    display flex
    flex-wrap wrap
    justify-content space-between
    .p-s-r-name
      width 32%
      line-height .32rem
      margin-bottom .08rem
      flex-shrink 0
      text-overflow ellipsis
      overflow hidden
      white-space nowrap
      font-size .12rem
      border 1px solid #343434
      background-color #060404
      &.selected
        background-color #484848
</style>