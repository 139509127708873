<template>
  <div class="recharge-active flex" @touchmove.stop="" v-show="localShow">
    <div class="main-wrap p-relative" ref="rechargeActMainWrap">
      <div class="h-w-wrap">
        <div class="w-h flex">
          <div class="total-amount-w">
            <div style="white-space: nowrap;text-overflow:ellipsis;overflow:hidden;line-height:.3rem;"
              class="p-relative">
              总金额:{{rechargeBonusInfo.total_amount}}&nbsp;&nbsp;
              <img class="l-amount-i" src="../../assets/activity/locked-amount-ico.png" />
              未解锁:{{rechargeBonusInfo.can_unlock}}
            </div>
            <div class="p-relative">
              已领取:{{rechargeBonusInfo.total_claimed}} 
              <a @click="showDetails" class="amount-details-link p-relative">
                详情
                <img class="g-d-icon" src="../../assets/activity/goto-detail-icon.png" />
              </a>
            </div>
          </div>
          <div class="flex unlock-w">
            <img class="un-amount-ico" src="../../assets/activity/unlocked-amount-ico.png" />
            <div class="flex">
              已解锁:
              <div style="width:.42rem;text-align: center;" class="flex-center">
                {{rechargeBonusInfo.can_claim}}
              </div>
            </div>
            <div @click="receiveClickHandle" class="receive-btn flex" :class="{disabled:receiveIsDisable}" >
            </div>
          </div>
        </div>
        <div class="bonus-item-container flex">

          <img class="bonus-icon-w" src="../../assets/activity/recharge-bonus.png" @click="showDetails2"/>
          <div>
            <div class="flex r-b-l-con">
              <div class="r-b-i-wrap flex p-relative" v-for="(item,i) of rechargeBonusList">
                <div class="arrow"></div>
                <div class="r-bonus-title">
                  {{item.gift_ratio}}%奖金
                </div>
                <img class="anim-ico" v-if="i == 0" src="../../assets/activity/r-b-item-bg1.png" />
                <img class="anim-ico" v-if="i == 1" src="../../assets/activity/r-b-item-bg2.png" />
                <img class="anim-ico" v-if="i == 2" src="../../assets/activity/r-b-item-bg3.png" />
                <img class="anim-ico" v-if="i == 3" src="../../assets/activity/r-b-item-bg4.png" />

                <div class="r-title-wrapper">
                  <div class="recharge-c-title">第{{item.recharge_count}}次充值</div>
                </div>

                <img class="bonus-i-arr-ico" src="../../assets/activity/bonus-i-arrow.png" />

              </div>
            </div>
            <div class="go-recharge-btn flex" @click="goRecharge" :class="`no-${rechargeCount}`">
            </div>
          </div>

        </div>

      </div>
      <img class="close-wid-btn" @click="bonusClickHandle" src="../../assets/activity/act-bonus-close.png" />
    </div>

    <!-- 活动详情 -->
    <div class="bonus-act-details flex" v-show="bonusDetailsShow">
      <div class="b-a-d-wrapper p-relative">
        <img class="czg-btn-gb" @click="bonusCloseClick" src="../../assets/activity/act-bonus-close.png" />
        <div class="b-a-w-content flex">
          <div class="b-a-w-border">
            <div class="big-info-w p-relative flex">
              <div class="already-unlock">
                <img class="a-b-c-unlock p-relative" src="../../assets/activity/unlocked-amount-ico.png" /> 
                已解锁：<span>{{rechargeBonusInfo.can_claim}}</span>
              </div>
              <div @click="receiveClickHandle" class="receive-btn flex" :class="{disabled:receiveIsDisable}">
              </div>
            </div>
            <div class="big-info-w two p-relative flex">
              <div class="total-amount-field">总金额：<span>{{rechargeBonusInfo.total_amount}}</span></div>
              <div class="how-to-unlock" @click="unlockLinkClick">如何解锁</div>
            </div>
            <div class="t-row hd">
              <div>时间</div>
              <div>充值金额</div>
              <div>奖励金额</div>
              <div>过期时间</div>
            </div>
            <div class="t-r-scroll" @scroll="pageScrollerScrolling">
              <div class="t-row" v-for="item of rechargeBonusPageData">
                <div>{{item.created_at}}</div>
                <div>{{item.recharge_amount}}</div>
                <div>{{item.total}}</div>
                <div>{{item.expire_at}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="how-to-unlock-bg flex" v-show="toUnlockTouristShow" @click="toUnlockTouristShow = 0">
        <div class="back" @click.stop="" v-html="getPretyHtml(rechargeBonusInfo.detail_unlock)">
        </div>
      </div>
    </div>

    <!-- 充值送 -->
    <div class="send-bonus-recharge flex" v-show="bonusSendShow">
      <div class="b-a-d-wrapper p-relative">
        <img class="czg-btn-gb" @click="sendBonusCloseClick" src="../../assets/activity/act-bonus-close.png" />
        <div class="b-a-w-content flex">
          <div class="b-a-w-border">
            <div class="r-b-i-desc">{{rechargeBonusInfo.desc}}</div>
            <div class="r-b-item-wrap flex">
              <div class="r-b-i-wrap flex p-relative" :class="`bg-${i}`" v-for="(item,i) of rechargeBonusList">
                
                <div class="r-bonus-title">第{{item.recharge_count}}次充值</div>

                <div class="second-item-bg bg1" v-if="i == 0"></div>
                <div class="second-item-bg bg2" v-if="i == 1"></div>
                <div class="second-item-bg bg3" v-if="i == 2"></div>
                <div class="second-item-bg bg4" v-if="i == 3"></div>
                
                <div class="gift-ratio-abc">
                  {{item.gift_ratio}}%奖金
                </div>

                <div class="recharge-row">
                  <div class="flex">
                    <div>最低存款额：</div><div>{{item.min_recharge}}元</div>
                  </div>
                  <div class="flex">
                    <div>最高奖励：</div><div>{{item.max_amount}}元</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Tool from '../../common/Tool.js';
import Request from '../../common/Request.js';
import { mapMutations } from 'vuex';

export default{
  props:{
    show:Number
  },
  data(){
    return{
      rechargeBonusInfo:{},
      rechargeCount:1,
      rechargeBonusList:[],
      rechargeBonusDataPager:{},
      rechargeBonusPageData:[],
      localShow:0,
      bonusDetailsShow:0,
      toUnlockTouristShow:0,
      bonusSendShow:0,
      bonusListPage:1,
      bonusListPageGetting:0,
    }
  },
  mounted(){
    this.layout();
  },
  methods:{
    ...mapMutations(['popMsg','showLoading','closeLoading']),
    getPretyHtml(source){
      if(!source) return source;
      return Tool.replaceAll(source,'font-size:','t');
    },
    goRecharge(){
      let found = 0;
      this.rechargeBonusList.forEach(b => {
        if(b.recharge_count == this.rechargeCount){
          found = 1;
        }
      })
      if(!found) return;
      
      this.localShow = 0
      this.$emit('goToRecharge')
    },
    unlockLinkClick(){
      this.toUnlockTouristShow = Math.random();
    },
    sendBonusCloseClick(){
      this.bonusSendShow = 0;
    },
    bonusClickHandle(){
      this.localShow = 0
    },
    showDetails(){
      this.bonusDetailsShow = Math.random()
      this.bonusListPage = 1;
      this.rechargeBonusPageData = []
      this.getBonusListPager()
    },
    showDetails2(){
      this.bonusSendShow = Math.random()
    },
    getBonusListPager(){
      //假数据
      // let shide = () => {
      //   this.rechargeBonusPageData = []
      //   let i = 0;
      //   while(i < 10){
      //     this.rechargeBonusPageData.push({
      //       created_at:'2023-8-5',
      //       recharge_amount:123,
      //       total:312,
      //       expire_at:'2023-8-5',
      //     })
      //     i++
      //   }
      // }
      // shide();
      // return
      this.showLoading()
      Request.get(`/pay/bonus_list?page=${this.bonusListPage}`).then(res => {
        this.rechargeBonusDataPager = res.data.data;
        this.rechargeBonusPageData = this.rechargeBonusPageData.concat(this.rechargeBonusDataPager.data)
      }).finally(() => {
        this.bonusListPageGetting = 0
        this.closeLoading()
      })
    },
    pageScrollerScrolling(e){
      let sTop = e.target.scrollTop;
      let scrollSpace = sTop + e.target.clientHeight
      let sub = e.target.scrollHeight - scrollSpace
      let {per_page,total} = this.rechargeBonusDataPager;
      let maxPage = Math.ceil(total / per_page)

      if(sub < 5 && this.bonusListPage < maxPage && !this.bonusListPageGetting){
        this.bonusListPageGetting = 1

        this.bonusListPage++
        this.getBonusListPager();
      }
    },
    getRechargeBonusList(){
      let dataHandle = (data) => {
        this.rechargeBonusInfo = data;
        this.rechargeCount = data.recharge_count;
        this.rechargeBonusList = data.rules;
      }
      if(process.env.NODE_ENV == 'development'){
        // this.rechargeBonusInfo.desc = 'HY电玩城每周提供四次【加密货币】【所有数字钱包】专属充值奖励活动，每次充值时送出对应比例的奖励！';
        // this.rechargeBonusInfo.detail_unlock = '教授副教授离开，都是分开。肯定是解放昆仑山反对，士大夫螺丝扣搭街坊了。势力扩大飞机是，顺利打开但是看了是的。'
        // this.rechargeBonusList = [
        //   {
        //     gift_ratio:2,
        //     recharge_count:1,
        //     min_recharge:100,
        //     max_amount:1000,
        //   },
        //   {
        //     gift_ratio:2,
        //     recharge_count:2,
        //     min_recharge:100,
        //     max_amount:1000,
        //   },
        //   {
        //     gift_ratio:2,
        //     recharge_count:3,
        //     min_recharge:100,
        //     max_amount:1000,
        //   },
        //   {
        //     gift_ratio:2,
        //     recharge_count:4,
        //     min_recharge:100,
        //     max_amount:1000,
        //   }
        // ]
        // return;

        let localData = Tool.getPayBonusInfo()
        if(localData){
          dataHandle(localData)
          return;
        }
      }
      this.showLoading()
      Request.get('/pay/bonus_info').then(res => {
        Tool.setPayBonusInfo(res.data.data)
        dataHandle(res.data.data)
      }).finally(() => {
        this.closeLoading()
      })
    },
    layout(){
      Tool.getBase64Image('/layout/recharge-act-bg.png').then(res => {
        this.$refs.rechargeActMainWrap.style.backgroundImage = `url(${res})`;
      })
    },
    bonusCloseClick(){
      this.bonusDetailsShow = 0
    },
    receiveClickHandle(){
      if(this.receiveIsDisable){
        return;
      }
      this.showLoading()
      Request.post('/pay/bonus_info_claim').then(res => {
        if(res.data && res.data.code == 0){
          this.popMsg('领取成功!');
          this.getRechargeBonusList();
        }
      }).catch(err => {
        let msg = '领取失败，请联系客服';
        if(err && err.response && err.response.data && err.response.data.msg){
          msg = err.response.data.msg;
        }
        this.popMsg(msg)
      }).finally(() => {
        this.closeLoading()
      })
    },
  },
  watch:{
    show(n){
      if(n){
        this.getRechargeBonusList();
      }
      this.localShow = n;
    }
  },
  computed:{
    maxGiftRatioV(){
      let maxGiftRatio = 0
      this.rechargeBonusList.forEach(rItem => {
        if(rItem.gift_ratio > maxGiftRatio){
          maxGiftRatio = rItem.gift_ratio
        }
      })
      return maxGiftRatio;
    },
    receiveIsDisable(){
      let {can_claim,total_claimed,total_amount} = this.rechargeBonusInfo;

      if(can_claim > 4 || 
        can_claim > 0 && (can_claim + total_claimed) == total_amount){
        return 0; // 可以领
      }else{
        return 1; // 不能领
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.recharge-active
  width 100%
  height 100%
  left 0
  top 0
  z-index 4
  position absolute
  background-color rgba(0,0,0,.25)
  .main-wrap
    width 6.04rem
    height 3.28rem
    background-size 100% 100%
    margin auto
    .close-wid-btn
      position absolute
      width .44rem
      right -.08rem
      top .42rem
    .h-w-wrap
      width 5.34rem
      height auto
      margin .92rem 0 0 .34rem
      .w-h
        width 100%
        height .68rem
        align-items center
        border-radius .06rem
        border .02rem solid #F6E5B7
        background-color #FDF5C6
        color #8E4F26
        font-size .15rem
        font-weight bold
        padding-left .14rem
        border #FFFDF4 solid 1px
        box-shadow 0 0 .1rem .06rem #911900
        .total-amount-w
          width 50%
          & > div
            line-height .25rem
          .l-amount-i
            width .2rem
            position relative
            top .02rem
          .amount-details-link
            color #38B501
            position absolute
            right .28rem
            bottom 0
            font-weight bold
            font-size .14rem
            border-bottom 1px solid
            line-height .22rem
            .g-d-icon
              width .1rem
              position absolute
              right -.13rem
              top .04rem
              height .19rem
        .unlock-w
          width 50%
          height 78%
          border-left .01rem solid #C6A57A
          justify-content center
          align-items center
          .un-amount-ico
            width .2rem;
            margin-right .07rem
        .receive-btn
          background-image url(../../assets/activity/bonus-recive-btn.png)
          width .95rem
          height .36rem
          background-size 100% 100%
          margin-left .04rem
          font-size .16rem
          font-weight bold
          line-height .34rem
          justify-content center
          &.disabled
            background-image url(../../assets/activity/bonus-r-disabled.png)
      .bonus-icon-w
        width 1.42rem
      .bonus-item-container
        padding .06rem 0 0 .06rem
        align-items flex-start
      .recharge-bonus-des
        .amount-details-link
          color #368C2B
          position absolute
          right -1.3rem
          bottom 0
          font-weight bold
          font-size .14rem
          border-bottom 1px solid
      .go-recharge-btn
        background-image url(../../assets/activity/go-to-recharge-btn.png)
        width .85rem
        height .3rem
        background-size 100% 100%
        justify-content center
        align-items center
        font-size .15rem
        font-weight bold
        line-height .34rem
        color #8e4f26
        margin-top .1rem
        &.no-2
          margin-left 1.33rem
        &.no-3
          margin-left 2.63rem
        &.no-4
          margin-left 3.94rem
        &:not(.no-1):not(.no-2):not(.no-3):not(.no-4)
          margin-left 3.94rem
          background-image url(../../assets/activity/bonus-r-disabled.png)
          color #515151
  .r-b-l-con
    margin-left .03rem
  .r-b-i-wrap
    width .8rem
    height 1.03rem
    margin-right .21rem
    border 1px solid #8D160E
    border-radius .06rem
    background-size 100% 100%
    flex-direction column
    align-items center
    color #0C0703
    background-color #FDF5C6
    &:last-child
      margin-right 0
      .bonus-i-arr-ico
        display none
    .bonus-i-arr-ico
      position absolute
      right -.16rem
      top .12rem
    .r-title-wrapper
      width .65rem
      margin-top .06rem
      border-radius .1rem
      background-color #F7D6A3
      box-shadow 0 .02rem .1rem .01rem #C99B77 inset
    .recharge-c-title
      transform scale(.8)
      height .2rem
      color #6C0000
    .arrow
      position absolute
      width .23rem
      left -.24rem
      top 50%
      transform translateY(-50%)
    .r-bonus-title
      transform scale(.8)
      font-size .16rem
    .anim-ico
      height .5rem
  .send-bonus-recharge
    .b-a-w-border
      background-color #CCC7A1
      .r-b-i-desc
        padding .05rem
        color #6E2F0E
        font-size .14rem
        padding .05rem
      .r-b-item-wrap
        width 100%
        justify-content space-around
      .r-b-i-wrap
        border none
        font-size .16rem
        padding .03rem 0
        width 1.23rem
        height 1.47rem
        margin-left 0
        background-size 100% 100%
        flex-direction column
        align-items center
        color #6c0000
        border solid #800000 .01rem
        .second-item-bg
          width .5rem
          height .5rem
          background-size 100% 100%
          &.bg1
            background-image url(../../assets/activity/r-b-item-bg1.png)
          &.bg2
            background-image url(../../assets/activity/r-b-item-bg2.png)
          &.bg3
            background-image url(../../assets/activity/r-b-item-bg3.png)
          &.bg4
            background-image url(../../assets/activity/r-b-item-bg4.png)
        .gift-ratio-abc
          position absolute
          top .9rem
        .r-bonus-title
          width 80%
          text-align center
          padding 0
          line-height .3rem
          font-size .16rem
          font-weight bold
          color #6C0000
          box-shadow 0 0 .06rem .01rem #D1A57E inset
          border-radius .2rem
          background-color #F9D5A3
          & > div
            font-weight 100
            font-size .12rem
        .recharge-row
          width 100%
          position absolute
          bottom 0
          left .01rem
          font-size .12rem
          color #6c0000
          .flex
            justify-content space-between
            padding 0 .04rem
  
  .send-bonus-recharge,.bonus-act-details
    width 100%
    height 100%
    left 0
    top 0
    z-index 1
    position absolute
    background-color rgba(0,0,0,.25)
    .how-to-unlock-bg
      position absolute
      width 100%
      height 100%
      top 0
      left 0
      background-color rgba(0,0,0,.25)
      .back
        width 3.52rem
        height 2.58rem
        margin auto
        background-image url(../../assets/activity/how-to-unlock-bg.png)
        background-size 100% 100%
        color #FFFFE0
        padding .14rem
        font-size .15rem
        line-height .24rem
        overflow-x hidden
        overflow-y auto
    .b-a-d-wrapper
      width 5.68rem
      padding-top .75rem
      margin auto
      background-image url(../../assets/activity/r-act-detail-bg.png)
      background-size 100% 100%
      .b-a-w-content
        width 100%
        height 2.5rem
        margin-top -.03rem
        border-bottom 1px solid #C5BD8F
        border-left 1px solid #C5BD8F
        border-right 1px solid #C5BD8F
        border-radius 0 0 .06rem .06rem
        font-size .14rem
        .b-a-w-border
          width 87%
          height 80%
          border-radius .06rem
          background-color #FDF5C6
          margin .2rem auto 0 auto
          border 1px solid #B2AD83
          color #7B3513
          .big-info-w
            width 100%
            margin auto
            height .35rem
            align-items center
            padding-left .42rem
            &:after
              content ' '
              display block
              width 90%
              height .01rem
              background-color #ece4b3
              position absolute;
              bottom 0
              left 50%
              transform translateX(-50%)
            &.two
              &:after
                display none
            span
              color #2F763E
            .receive-btn
              background-image url(../../assets/activity/bonus-recive-btn.png)
              width .78rem
              height .27rem
              background-size 100% 100%
              font-size .16rem
              font-weight bold
              line-height .34rem
              justify-content center
              &.disabled
                background-image url(../../assets/activity/bonus-r-disabled.png)
            .how-to-unlock
              color #39b401
              position relative
              &:after
                height .15rem
                width .09rem
                top .03rem
                right -.13rem
                display block
                content ' '
                position absolute
                background-image url(../../assets/activity/goto-detail-icon.png)
                background-size 100% 100%
            .already-unlock
              width 3.3rem
              .a-b-c-unlock
                top .02rem
                width .18rem
            .total-amount-field
              width 3.59rem
          .t-r-scroll
            width 4.45rem
            height 1rem
            margin auto
            overflow-x hidden
            overflow-y auto
          .t-row
            display flex
            text-align center
            font-size .12rem
            line-height .22rem
            &.hd
              width 4.45rem
              line-height .27rem
              margin auto
              border-radius .04rem
              background-color #F9D5A3 !important
              font-size .14rem
              box-shadow 0 0 .1rem .01rem #C99B77 inset
            & > div
              width 25%
      .czg-btn-gb
        position absolute
        width .52rem
        right -.17rem
        top .34rem
  .send-bonus-recharge
    .b-a-d-wrapper
      width 6.2rem
    .r-b-i-wrap
      margin-right 0
</style>

<style lang="stylus">
.how-to-unlock-bg
  .back
    dl
      width 100%
      height 2.82rem
      overflow-x hidden
      overflow-y auto
    dt
      font-size .16rem
      margin-top .14rem
      &:first-child
        margin-top 0
    dd
      margin-left .22rem
</style>