<template>
  <div class="op-duihuan-wrap">
    <div class="flex p-relative">
      <div class="amount-title">可兑换金额</div>
      <div class="amount-dh flex">
        <img class="jine" src="../../assets/duihuan/jine.png" />
        <span>
          &nbsp;&nbsp;{{withdrawInfo.max_draw?withdrawInfo.max_draw:0}}
        </span>
      </div>
      <div class="record-link" @click="gotoRecordeClick">
        兑换记录
      </div>
    </div>
    <div class="flex info-des f-row" :class="{tixianchongzhi:wayId == 'way-2'}">
      温馨提示：需再投注<span>{{withdrawInfo.debt?withdrawInfo.debt:0}}</span>元，
      即可不扣除行政费用与优惠回收费!
    </div>
    <div v-if="wayId == 'way-2'" style="color:#fff;font-size:.12rem;text-align:left;">
      提现充值到账快，避免银行卡流水存取款次数过多导致银行卡风控！<br/>不用再担心存取款延迟问题导致影响游戏体验！
    </div>
    <div class="flex f-row" v-if="wayId != 'way-3'">
      <div class="filed-input">兑换金额：</div>
      <form>
        <input class="amount-input" type="number" v-model="duihuanAmount"
          @beforepaste="amountBeforepaste" @input="amountInputing"
          :placeholder="`${wayId == 'way-2'?'':'提交金额'+amountMin+'-'+amountMax}`">
      </form>
    </div>
    <!-- 免提直充，不显示账号信息 -->
    <div class="flex f-row" :class="wayId" v-if="wayId != 'way-2'">
      <div class="filed-input" v-if="wayId != 'way-3'">{{receiveWay}}：</div>
      <!-- way-3 数字钱包 -->
      <div class="alipy-account" :class="wayId" v-if="wayId == 'way-3'">
        <ShuziqianbaoSelector style="width:100%;height:100%;line-height:.3rem"
          :dataList="walletList" @change="walletSelecChange"
          :defaultIndex="-1" menuHeight=".71rem" />
        <!-- <Selector defaultTxt="选择提现平台" style="width:100%;height:100%;line-height:.3rem"
          :dataList="walletList" @change="walletSelecChange"
          :defaultIndex="-1" menuHeight=".71rem" /> -->
      </div>
      <div class="alipy-account" v-else>
        {{payWayAccount}}
      </div>

      <div style="height:.2848rem;" v-if="wayId != 'way-3'">
        <img class="show-alipay-btn ease-btn-effe" v-if="payWayAccount"
          src="../../assets/duihuan/show-alipay-btn.png"
          @click="showAlipayClick" />
        <img class="show-alipay-btn ease-btn-effe" v-else
          src="../../assets/duihuan/binding-alipay-btn.png" 
          @click="bandingAlipayClick" />
      </div>
    </div>
    <div class="flex f-row" v-if="wayId == 'way-3'">
      <div class="filed-input">兑换金额：</div>
      <form>
        <input class="amount-input" type="number" v-model="duihuanAmount"
          @beforepaste="amountBeforepaste" @input="amountInputing"
          :placeholder="`${wayId == 'way-2'?'':'提交金额'+amountMin+'-'+amountMax}`">
      </form>
    </div>
    <!-- 免提直充，不显示账号信息 -->
    <div class="flex f-row" :class="wayId" v-if="wayId == 'way-3'">
      <div class="filed-input">{{receiveWay}}：</div>
      <div class="alipy-account">
        {{payWayAccount}}
      </div>
      <img class="show-alipay-btn ease-btn-effe" v-if="payWayAccount"
        src="../../assets/duihuan/show-alipay-btn.png"
        @click="showAlipayClick" />
      <img class="show-alipay-btn ease-btn-effe" v-else
        src="../../assets/duihuan/binding-alipay-btn.png" 
        @click="bandingAlipayClick" />
    </div>
    <img @click="duihuanSubClick"
      class="duihuan-sub-btn ease-btn-effe"
        src="../../assets/duihuan/duihuan-sub-btn.png" />
  </div>
</template>
<script>
// import Selector from '../../components/common/Selector.vue'
import ShuziqianbaoSelector from '../../components/common/ShuziqianbaoSelector.vue'
import Tool from '../../common/Tool.js'
import { mapMutations } from 'vuex'
export default{
  props:{
    userInfo:Object,
    currMenuItem:Object,
    withdrawInfo:Object,
    wayId:String|Number,
  },
  data(){
    return{
      duihuanAmount:'',
      walletListIndex:-1,
      isNoWallet:0,
    }
  },
  methods:{
    ...mapMutations(['popMsg']),
    amountBeforepaste(){
      clipboardData.setData('text',clipboardData.getData('text').replace(/[^\d]/g,''))
    },
    amountInputing(e){
      e.srcElement.value = e.srcElement.value.replace(/[^\d]/g,'');
    },
    walletSelecChange(i){
      this.walletListIndex = i;
      let currWallet = this.walletList[this.walletListIndex];
      this.$emit('walletChage',currWallet);
    },
    gotoRecordeClick(){
      this.$emit('showRecordClick');
    },
    showAlipayClick(){
      this.$emit('showAlipayClick',this.wayId)
    },
    bandingAlipayClick(){
      this.$emit('bandingAlipayClick',this.wayId)
    },
    duihuanSubClick(){
      if(this.wayId != 'way-2'){
        if(!this.payWayAccount || !this.payWayAccount.trim()){
          let msg = '请绑定账号';
          if(this.wayId == 'way-0'){
            msg = '请绑定支付宝';
          }else if(this.wayId == 'way-1'){
            msg = '请绑定银行卡';
          }else if(this.wayId == 'way-3'){
            msg = this.walletList.length?'请选择数字钱包':'请绑定数字钱包';
          }
          this.popMsg(msg);
          return;
        }
      }

      let outputParam = {
        dhAmount:this.duihuanAmount,
      };

      let duihuanAmount = 1*this.duihuanAmount;
      if(this.wayId != 'way-2'){
        let amountMin = 1*this.amountMin;
        let amountMax = 1*this.amountMax;
        if(!(amountMin <= duihuanAmount && duihuanAmount <= amountMax)){
          this.popMsg(`请输入${this.amountMin}到${this.amountMax}之间的金额`)
          return;
        }
        outputParam.account = this.payWayAccount;
      }else if(!duihuanAmount || duihuanAmount < 1){
        this.popMsg('请输入有效金额')
        return;
      }
      let max_draw = this.withdrawInfo.max_draw?1*this.withdrawInfo.max_draw:0;
      if(duihuanAmount > max_draw){
        this.popMsg(`您最多可兑换${max_draw}！`)
        return;
      }

      
      this.$emit('duihuanSubClick',outputParam)
    },
  },
  watch:{
    wayId(n){
      this.duihuanAmount = '';
    },
    currMenuItem(c){
      this.isNoWallet = c.id == 139 ? 1 : 0;
      // if(c.id == 139){
      //   this.getNopayInfo();
      // }
    }
  },
  computed:{
    walletList(){
      if(this.userInfo.wallets && this.userInfo.wallets.length){
        console.log(this.userInfo.wallets,'abcd')
        return this.userInfo.wallets.map(w => {
          return{
            ...w,
            name:`${w.wallet_name}:${w.address}`
          }
        });
      }
      return []
    },
    payWayAccount(){
      if(this.wayId == 'way-0'){
        return this.userInfo.alipay;
      }else if(this.wayId == 'way-1'){
        if(this.userInfo && this.userInfo.bank && this.userInfo.bank.bank_account){
          return this.userInfo.bank.bank_account;
        }
      }else if(this.wayId == 'way-3'){
        if(this.walletListIndex > -1 && this.walletList.length){
          return this.walletList[this.walletListIndex].address;
        }
        
      }
      // else if(Tool.walletId.includes(this.wayId)){
      //   if(this.userInfo.wallets && this.userInfo.wallets.length){
      //     let found = null;
      //     this.userInfo.wallets.forEach(wallet => {
      //       if(wallet.third_id == this.currMenuItem.third_id){
      //         found = wallet;
      //       }
      //     })
      //     if(found){
      //       return found.address;
      //     }
      //   }
      // }
    },
    receiveWay(){
      if(this.wayId == 'way-0'){
        return '收款支付宝';
      }else if(this.wayId == 'way-1'){
        return '收款银行卡';
      }else if(this.wayId == 'way-3' || Tool.walletId.includes(this.wayId)){
        return '数字钱包';
      }
    },
    currentMenuId(){
      if(this.menuItems.length && this.menuItems[this.menuSelectIndex]){
        return this.menuItems[this.menuSelectIndex].id;
      }
    },
    amountMin(){
      if(this.withdrawInfo.chargeout_setting){
        const setting = this.withdrawInfo.chargeout_setting;
        let pKey = '';
        if(this.wayId == 'way-0'){
          pKey = 'out_lower_limit_alipay';
        }else if(this.wayId == 'way-1'){
          pKey = 'out_lower_limit_bank';
        }else if(this.wayId == 'way-3'){
          pKey = 'out_lower_limit_wallet';
        }else if(this.wayId == 'way-2'){
          pKey = 'out_lower_limit_w_recharge';
        }
        return setting[pKey];
      }
      return 0;
    },
    amountMax(){
      if(this.withdrawInfo.chargeout_setting){
        const setting = this.withdrawInfo.chargeout_setting;

        let pKey = '';
        if(this.wayId == 'way-0'){
          pKey = 'out_upper_limit_alipay';
        }else if(this.wayId == 'way-1'){
          pKey = 'out_upper_limit_bank';
        }else if(this.wayId == 'way-3'){
          pKey = 'out_upper_limit_wallet';
        }else if(this.wayId == 'way-2'){
          pKey = 'out_upper_limit_w_recharge';
        }
        return setting[pKey];
      }
      return 0;
    },
  },
  components:{
    // Selector,
    ShuziqianbaoSelector
  }
}
</script>

<style scoped lang="stylus">
.op-duihuan-wrap
  padding .12rem 0 0 .15rem
  .show-alipay-btn
    width .56rem
    margin-left .1rem
  & > div
    align-items center
    margin-bottom .1rem
    &.info-des
      font-size .12rem
      color #FFFFFF
      span
        color red
        font-size .16rem
    &.f-row
      margin-bottom .2rem
      &.tixianchongzhi
        margin-bottom 0
      &.way-3
        margin-bottom .09rem
    .amount-dh
      margin-left .2rem
      align-items center
      font-weight bold
      .jine
        width .18rem
    .record-link
      text-decoration underline
      position absolute
      right .2rem
      top .02rem
    .filed-input
      width .88rem
      text-align right
    .alipy-account
      width 2.56rem
      height .3rem
      overflow hidden
      text-align left
      line-height .3rem
      border-radius .04rem
      background-color #343434
      padding-left .04rem
      color #fff1df
      &.way-3
        width 100%
        height auto
        padding-left 0
        overflow visible
        background-color transparent
      // &/deep/ .p-s-r-name
      //   line-height .21rem
      //   padding .02rem 0 .02rem .1rem
      //   &:nth-child(odd)
      //     background #626262
    input
      width 2.56rem
      height .3rem
      border-radius .04rem
      background-color #343434
      &.amount-input
        width 3.2rem
        padding-left .04rem
  .duihuan-sub-btn
    width 1.12rem
</style>